import React from 'react'
import { useRecoilValue } from 'recoil'
import { AtomUserid } from '../atom/AtomUserid'
import { AtomUsername } from '../atom/AtomUsername'


const UserDetails = () => {
  const usersname = useRecoilValue(AtomUsername)
  const userid = useRecoilValue(AtomUserid)

  return (
    <>
   
    </>

  )
}

export default UserDetails