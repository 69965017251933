import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Display } from '../atom/Display'
import AllBackgroundImage from './AllBackgroundImage'
import AllBages from './AllBages'
import AllFont from './AllFont'
import AllProfileImage from './AllProfileImage'
import AllRankcard from './AllRankcard'
const DisplayWhat = () => {
    const data  = useRecoilValue(Display)
     if(data === 'Badges')
    {
        return(<AllBages/>)
    } else if(data === 'Background Image')
    {
        return(<AllBackgroundImage/>)
    } else if(data==='Rank Image'){
        return(<AllRankcard/>)
    }else if(data==='Font'){
        return(<AllFont/>)
    }else
    {
        console.log(data)
        return(
            <AllProfileImage/>
        )
    }
  
}

export default DisplayWhat