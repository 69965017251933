/* eslint-disable no-unused-vars */
import { useState, useEffect, Suspense } from "react";
import { getProfile, getUsernameAndUrl } from "../api";
import { useParams } from "react-router-dom";
import React from "react";
import "../i.css";
const Getprofile = () => {
  const [description, setDescription] = useState("Sample description here");
  const [userid, setuserid] = useState("382716535112");
  const [level, setlevel] = useState("11");
  const [birthinfo, setbirthinfo] = useState("10/sept");
  const [RP, setRP] = useState("0");
  const [font, setfont] = useState([]);
  const [profilecard, setprofilecard] = useState("");
  const [curfont, setcurfont] = useState("");
  const [usersprofile, setusersprofile] = useState("");
  const [usersname, setusersname] = useState("reiben");
  const [imageURL, setImageURL] = useState("");
  const [currentbg, setcurrentbg] = useState("");
  const [badge1, setbadge1] = useState("");
  const [badge2, setbadge2] = useState("");
  const [badge3, setbadge3] = useState("");
  const [badge4, setbadge4] = useState("");
  const [work, setwork] = useState("")
  const user = useParams().userid;
  const [color1, setcolor1] = useState('#000')

  useEffect(() => {
    (async () => {
      try {
        getUsernameAndUrl(user).then((data) => {
          console.log(data);
          setusersprofile(data.avatarURL);
          setusersname(data.tag);
        });
        getProfile(user).then((data) => {
          console.log("profile", data);
          // works
          setwork(data.work)
          setDescription(data.description);
          setuserid(data.userid);
          setlevel(data.level);
          setbirthinfo(data.birthday);
          setcolor1(data.color)
          setfont(data.font);
          setcurfont(data.curFont);
          setRP(data.balancerp);
          const arr = [];
          const badgesObj = require("../badges.json");
          for (let i = 0; i < badgesObj.length; i++) {
            for (let j = 0; j < data.curbadges.length; j++) {
              if (badgesObj[i].tag === data.curbadges[j]) {
                arr.push(badgesObj[i].url);
              }
            }
          }
          console.log(arr);
          setbadge1(arr[0]);
          setbadge2(arr[1]);
          setbadge3(arr[2]);
          setbadge4(arr[3]);
          let obj = require("../profileimg.json");
          for (let i = 0; i < obj.length; i++) {
            if (obj[i].tag === data.curprofile) {
              setImageURL(obj[i].url);
              break;
            }
          }
          obj = require("../BackgroundImage.json");
          for (let i = 0; i < obj.length; i++) {
            if (obj[i].tag == data.curprofilebg) {
              setcurrentbg(obj[i].url);
              break;
            }
          }
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);
  const fontfordesc= { 
    wordWrap: 'break-word', 
    fontFamily:curfont
  }
  const image = {
    backgroundImage: `url('${currentbg}')`,
    marginLeft: '0px',
     marginTop: '0px'
  };
const stylec = {
  color:color1
}
  return (
    <div style={{ 
      paddingBottom: "70px", 
      backgroundColor: "#36393e",
     }}>
      <div className="bgimg" style={image}>
        <div className="card" style={stylec}>
          <h1 className="work" style={{ padding: "6px 6px" }}>
            {work}
          </h1>
          <div className="profimages">
            <img 
              id="bgimage1"
              alt="img1"
              height="325px"
              width="700px"
              src={imageURL}
            />
            <img className="image2" alt="img2" src={usersprofile}/>
          </div>
          <div className="userdetails">
            <h1>{usersname}</h1>
            <p>{userid}</p>
          </div>
          <div className="seperatebadge" style={{ display: "flex" }}>
            <div className="details">
              <div>
                <h1>Level</h1>
                <p>{level}</p>
              </div>
              <div>
                <h1>BalanceRp</h1>
                <p>{RP}</p>
              </div>
              <div>
                <h1>Birthday</h1>
                <p>{birthinfo}</p>
              </div>
            </div>
            <div
              className="badges"
              style={{
                paddingLeft: "36px",
                textAlign: "center",
              }}
            >
              <h1 style={{ padding: "10px", textAlign: "center" }}>Badges</h1>
              <div style={{display:'grid',gridTemplateColumns:'repeat(2,1fr)'}}>
              <img
                src={badge1}
                height="70"
                width="70"
                alt="badge1"
                style={{ padding: "5px 10px" }}
              />
              <img
                src={badge2}
                height="70"
                width="70"
                alt="badge2"
                style={{ padding: "5px 10px" }}
              />
              <img
                src={badge3}
                height="70"
                width="70"
                alt="badge3"
                style={{ padding: "5px 10px" }}
              />
              <img
                src={badge4}
                height="70"
                width="70"
                alt="badge4"
                style={{ padding: "5px 10px" }}
              />
              </div>
              
            </div>
          </div>
          <div className="description" style={{
        paddingTop: '40px',
        paddingLeft: '10px'
    }}>
        <h1 style={{ padding: "5px 0px" }}>Description</h1>
        <p style={fontfordesc}>{description}</p>
    </div>
        </div>
      </div>
    </div>
  );
};

export default Getprofile;
