import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { AtomBalanceRP } from '../atom/AtomBalanceRP'
import { AtomBirthdayDisplay } from '../atom/AtomBirthdayDisplay'
import { AtomCurrentBadge1Url } from '../atom/AtomCurrentBadge1Url'
import { AtomCurrentBadge2Url } from '../atom/AtomCurrentBadge2Url'
import { AtomCurrentBadge3Url } from '../atom/AtomCurrentBadge3Url'
import { AtomCurrentBadge4Url } from '../atom/AtomCurrentBadge4Url'
import { AtomLevel } from '../atom/AtomLevel'


const UserLevelsInfo = () => {
    const birth = useRecoilValue(AtomBirthdayDisplay)
    const level = useRecoilValue(AtomLevel)
    const RP =      useRecoilValue(AtomBalanceRP)
    const badge1 = useRecoilValue(AtomCurrentBadge1Url)
    const badge2 = useRecoilValue(AtomCurrentBadge2Url)
    const badge3 = useRecoilValue(AtomCurrentBadge3Url)
    const badge4 = useRecoilValue(AtomCurrentBadge4Url)

  return (
     
    <div class='block sm:flex'>
    <div class='flex justify-center items-center'>
        <div class='px-2'>
            <h1>
                Level
            </h1>
            <p>{level}</p>
        </div>
        <div class='px-2'>
            <h1>
                BalanceRp
            </h1>
            <p>{RP}</p>
        </div><div class='px-2'>
            <h1>
                Birthday
            </h1>
            <p>{birth}</p>
        </div>
    </div>
    <div className="Bbadges">
        <h1 class='text-center m-1'>Badges</h1>
        <div class='flex justify-center items-center'>
        <img src={badge1} height="50" width="50" alt="badge1" class='m-2'/>
            <img src={badge2} height="50" width="50" alt="badge2" class='m-2'/>
            <img src={badge3} height="50" width="50" alt="badge3" class='m-2'/>
            <img src={badge4} height="50" width="50" alt="badge4" class='m-2'/>
        </div>
            
    </div>
    </div>
  )
}

export default UserLevelsInfo
