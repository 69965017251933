import React from 'react'
import { useRecoilValue } from 'recoil'
import { AtomCurrentProfileUrl } from '../atom/AtomCurrentProfileUrl'
import { AtomUsersUrl } from '../atom/AtomUsersUrl'
import { AtomUserid } from '../atom/AtomUserid'
import { AtomUsername } from '../atom/AtomUsername'


const MainImage = () => {
  const IMAGE = useRecoilValue(AtomCurrentProfileUrl)
  const MyProfilePicture =useRecoilValue(AtomUsersUrl)
  const usersname = useRecoilValue(AtomUsername)
  const userid = useRecoilValue(AtomUserid)
  return (
    <>
    <img id="imageMain" alt='img1' src={IMAGE} key={IMAGE} class="h-full w-full object-cover object-center"/>
    <div>
    <img id="pfp" alt='img2' src={MyProfilePicture} class="relative left-2 bottom-20  rounded-full h-28"/>
    <div class ='p-2'>
    <h1>{usersname}</h1>
    <p>{userid}</p>
    </div>
    
    </div>
    
</>
  )
}

export default MainImage