import React from "react";
import { RecoilRoot } from "recoil";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import "./index.css";
import Error from "./routes/Error";
import UserDetails from "./routes/UserDetails";
import Server from "./routes/Server";
import Responsive from "./routes/Responsive";
import Getprofile from "./routes/Getprofile";
import Marketplacece from "./routes/Marketplace";
import ManageServers from "./routes/ManageServers";
import Top from "./routes/Top";
import CustomizeRank from "./routes/CustomizeRank";
import Apex from "./routes/Apex";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement:<Error/>
  },
  {
    path: "/profile",
    element: <UserDetails />,
  },
  {
    path: "/servers",
    element: <Server></Server>,
  },
  {
    path: "/getprofile/:userid",
    element: <Getprofile></Getprofile>,
  },
  {
    path: "/Marketplace",
    element: <Marketplacece />,
  },
  {
    path: "/ManageServers",
    element: <ManageServers />,
  },
  {
    path: "/top",
    element: <Top></Top>,
  },
  {
    path: "/:userid/rankcard",
    element: <CustomizeRank />,
  },
  {
    path: "/:platform/:name/apex",
    element: <Apex />,
  },
  {
    path: "/responsive",
    element: <Responsive />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RecoilRoot>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </RecoilRoot>
);
