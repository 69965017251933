const BASE_URL = 'https://reo-production.up.railway.app';
// https://reo-fernandes.xyz/
const getAlive = () => fetch(BASE_URL).then(res=>res.json())
const getProfile = (id) => fetch(`${BASE_URL}/api/profile/${id}`).then(res=>res.json())
const getServers = () => fetch(`${BASE_URL}/api/getservers`).then(res=>res.json())
const getPassport = () => fetch(`${BASE_URL}/api/getpassport`).then(res=>res.json())
const getUsernameAndUrl = (id) => fetch(`${BASE_URL}/api/userinfo/${id}`).then(res=>res.json())
const getTop = ()  => fetch(`${BASE_URL}/api/top`).then(res=>res.json())
const customizeProfileData = (userid,description,profile,bg,color,birthdate,font,badge1,badge2,badge3,badge4) => fetch(`${BASE_URL}/api/updateprofile/${userid}/${description}/${profile}/${bg}/${color}/${birthdate}/${font}/${badge1}/${badge2}/${badge3}/${badge4}`).then(res=>res.json())
module.exports = {
    getAlive,
    getProfile,
    getServers,
    getPassport,
    getUsernameAndUrl,
    getTop,
    customizeProfileData

}