import React from 'react'
import Footer from '../components/Footer'
import MarketInfo from '../components/marketPlace/MarketInfo'
import Navbar from '../components/Navbar'
import '../index.css'
const Marketplace = () => {

  return (
    <>
    <Navbar></Navbar>
    <MarketInfo></MarketInfo>
    <Footer></Footer>
    </>
  )
}

export default Marketplace