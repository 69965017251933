import { useState, useEffect, Suspense}  from "react";
import { Canvas } from "@react-three/fiber";
import { RectangleStackIcon, PencilSquareIcon, ArrowTrendingUpIcon ,UserGroupIcon } from '@heroicons/react/24/outline'
import { Model } from "../Model";
import { OrbitControls } from "@react-three/drei";
import React from 'react'

import * as THREE from "three";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
const Home = () => {
  const features = [
    {
      name: 'Customize everything',
      description:
        `Users have the freedom to personalize every aspect of their experience. From customizing their profile and rank card, including profile image, color, description, birthday, badges, and background images`,
      icon: PencilSquareIcon,
    },
    {
      name: 'Battle Pass System',
      description:
        'The "reo battle pass" system is a feature in the Discord bot where users can earn rewards such as profile and rank cards by leveling up. It provides a fun and engaging way to reward users for using the bot and adds a layer of customization to their Discord experience.',
      icon: RectangleStackIcon,
    },
    {
      name: 'Ranking',
      description:
        'The ranking feature also allows users to compete with each other and see where they stand in comparison to others, by displaying the top users in a chart. It creates a sense of competition and motivation for users to progress and earn rewards, and also allows them to showcase their progress and achievements to others.',
      icon: ArrowTrendingUpIcon,
    },
    {
      name: 'Ecosystem',
      description:
        'The subarashi ecosystem integration means that the bot will be part of a larger system, communicating and interacting with other components for a seamless experience. It also implies regular maintenance and updates.',
      icon: UserGroupIcon,
    },
  ]
  return (
    <>
      <Navbar />
      <div class="flex items-center justify-between">
        <div class="home m-3 md:my-10 px-6 lg:px-8">
          <h1>Hi</h1>
          <h1>Im REO</h1>
          <p class="font-poppins mx-auto mt-2 max-w-2xl text-lg leading-8 text-gray-600">
          Reo is back, offering an engaging and interactive experience with a variety of features to keep users entertained. With a dynamic ranking system, users can enjoy the thrill of competition as they climb the leaderboard. The integrated economy system adds a new dimension to the gameplay, rewarding users for their participation and dedication. Moreover, Reo's profile customization options allow users to express their creativity and personalize their experience. Explore Reo and uncover even more exciting features that make it a unique and captivating platform.     
          </p>
          <button class="bg-reored text-white font-bold p-2 rounded-lg my-1 hover:bg-black mt-2" onClick={ () =>window.open("https://discord.gg/v2TzrDz5Kz")}>
            Join Discord
          </button>
        </div>
        <div class="hidden lg:block lg:h-screen lg:w-screen bg-lightgrey">
        <Canvas camera={{ position: [0.5, 0.5, 2], fov: 55 }}
        >
          <ambientLight intensity={0.5} />
          <ambientLight intensity={0.2} />
          <directionalLight intensity={1} />
          <Suspense fallback={null}>
            <Model position={[0.025, -0.9, 0]} />
          </Suspense>
          <OrbitControls />
        </Canvas>
        </div>
      </div>
      <div class="bg-white pb-24 sm:pb-32 md:pt-4">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="sm:text-center">
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Reo レオ</p>
          <p class="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 font-poppins">
          It offers unique features like Battle pass & roleplay system, integrated with subrashi ecosystem. It provides an engaging and immersive experience for users. It is easy to use and accessible to all skill levels. Upgrade your Discord experience with Reo.
          </p>
        </div>

        <div class="mt-20 max-w-lg sm:mx-auto md:max-w-none">
          <div class="block sm:grid gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} class="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                <div class="flex h-12 w-12 items-center justify-center rounded-xl bg-reored text-white sm:shrink-0 hover:bg-black">
                  
                  <feature.icon class="h-8 w-8" aria-hidden="true" />
                </div>
                <div class="sm:min-w-0 sm:flex-1">
                  <p class="text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                  <p class="mt-2 text-base leading-7 text-gray-600 font-poppins">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
      <Footer></Footer>
    </>
  );
};

export default Home;
