import React, { useState, useEffect } from "react";

import { AtomCurrentProfileName } from "../components/atom/AtomCurrentProfileName";
import { AtomUsersUrl } from "../components/atom/AtomUsersUrl";
import { AtomUsername } from "../components/atom/AtomUsername";
import { AtomWork } from "../components/atom/AtomWork";
import { AtomDescription } from "../components/atom/AtomDescription";
import { AtomUserid } from "../components/atom/AtomUserid";
import { AtomLevel } from "../components/atom/AtomLevel";
import { AtomBirthdayDisplay } from "../components/atom/AtomBirthdayDisplay";
import { AtomCurrentFontName } from "../components/atom/AtomCurrentFontName";
import { AtomColor } from "../components/atom/AtomColor";
import { AtomBalanceRP } from "../components/atom/AtomBalanceRP";
import { AtomCurrentProfileUrl } from "../components/atom/AtomCurrentProfileUrl";
import { AtomCurrentProfileAvailable } from "../components/atom/AtomCurrentProfileAvailable";
import { AtomCurrentBadgeAvailable } from "../components/atom/AtomCurrentBadgeAvailable";
import { AtomCurrentBgName } from "../components/atom/AtomCurrentBgName";
import { AtomCurrentBgAvailable } from "../components/atom/AtomCurrentBgAvailable";
import { AtomCurrentBadge1Name } from "../components/atom/AtomCurrentBadge1Name";
import { AtomCurrentBadge1Url } from "../components/atom/AtomCurrentBadge1Url";
import { AtomCurrentBadge2Name } from "../components/atom/AtomCurrentBadge2Name";
import { AtomCurrentBadge2Url } from "../components/atom/AtomCurrentBadge2Url";
import { AtomCurrentBadge3Name } from "../components/atom/AtomCurrentBadge3Name";
import { AtomCurrentBadge3Url } from "../components/atom/AtomCurrentBadge3Url";
import { AtomCurrentBadge4Name } from "../components/atom/AtomCurrentBadgeName";
import { AtomCurrentBadge4Url } from "../components/atom/AtomCurrentBadge4Url";
import { AtomCurrentBgUrl } from "../components/atom/AtomCurrentBgUrl";
import { getPassport } from "../api";
import { Listbox } from "@headlessui/react";
import { useRecoilState } from "recoil";
import axios from "axios";
import { AtomBirthdayConverted } from "./atom/AtomBirthdayConverted";
import { AtomCurrentFontAvailable } from "./atom/AtomCurrentFontAvailable";
const Form = () => {
  const h1 = {
    color: "#000",
    fontFamily: "Bebas Neue",
    fontSize: "30px",
  };
  const inputbox = {
    border: "1px solid #D6CBCB",
    backgroundColor: "rgba(225, 222, 222, 0.17)",
  };
  const [currentprofilename, setcurrentprofilename] = useRecoilState(AtomCurrentProfileName);
  const [usersprofile, setusersprofile] = useRecoilState(AtomUsersUrl);
  const [usersname, setusersname] = useRecoilState(AtomUsername);
  const [work, setwork] = useRecoilState(AtomWork);
  const [Description, setDescription] = useRecoilState(AtomDescription);
  const [userid, setuserid] = useRecoilState(AtomUserid);
  const [level, setlevel] = useRecoilState(AtomLevel);
  const [birthdayDisplay, setbirthdayDisplay] =useRecoilState(AtomBirthdayDisplay);
  const [birthdayValue, setbirthdayValue] = useState(AtomBirthdayConverted)
  const [font, setfont] = useRecoilState(AtomCurrentFontName);
  const [color, setcolor] = useRecoilState(AtomColor);
  const [currentFont, setcurrentFont] = useRecoilState(AtomCurrentFontName);
  const [BalanceRp, setBalanceRp] = useRecoilState(AtomBalanceRP);
  const [ProfileimageUrl, setProfileimageurl] = useRecoilState(AtomCurrentProfileUrl);
  const [AllProfileAvailable, setAllProfileAvailable] = useRecoilState(AtomCurrentProfileAvailable );
  const [AllFontsAvailable, setAllFontsAvailable] = useRecoilState(AtomCurrentFontAvailable)
  const [AllBadgesAvailable, setAllBadgesAvailable] = useRecoilState(AtomCurrentBadgeAvailable);
  const [CurrentBackgroundName, setCurrentBackgroundName] =useRecoilState(AtomCurrentBgName);
  const [CurrentBackgroundUrl, setCurrentBackgroundUrl] =useRecoilState(AtomCurrentBgUrl);
  const [AllBgAvailable, setAllBgAvailable] = useRecoilState(AtomCurrentBgAvailable);
  const [Badge1Name, setBadge1Name] = useRecoilState(AtomCurrentBadge1Name);
  const [Badge1Url, setBadge1Url] = useRecoilState(AtomCurrentBadge1Url);
  const [Badge2Name, setBadge2Name] = useRecoilState(AtomCurrentBadge2Name);
  const [Badge2Url, setBadge2Url] = useRecoilState(AtomCurrentBadge2Url);
  const [Badge3Name, setBadge3Name] = useRecoilState(AtomCurrentBadge3Name);
  const [Badge3Url, setBadge3Url] = useRecoilState(AtomCurrentBadge3Url);
  const [Badge4Name, setBadge4Name] = useRecoilState(AtomCurrentBadge4Name);
  const [Badge4Url, setBadge4Url] = useRecoilState(AtomCurrentBadge4Url);
  useEffect(() => {
    console.log(currentFont) 
    
  }, [])
  
  
  const BackgroundJson = require("../BackgroundImage.json");
  const ProfileJson = require("../profileimg.json")
  const FontJson = require("../font.json")
  const BadgesJson = require("../badges.json")


  const textAreaDetails = (e) => {
    setDescription(e.target.value);
  };
  const birthdayfun = (e) => {
    const date = new Date(e.target.value);
    const month = date.toLocaleString("default", { month: "short" });
    setbirthdayDisplay(`${date.getDate()}/${month}`);
  };
  const addThisBg = (e) => {
  
    BackgroundJson.forEach((data)=> {
      if (data.tag == e.target.value) {
        setCurrentBackgroundName(data.tag)
        setCurrentBackgroundUrl(data.url)

      }
    })
  };
  const addThisProfile = (e)=>{
    ProfileJson.forEach((data) =>{
      if (data.tag == e.target.value) {
        setcurrentprofilename(data.tag)
        setProfileimageurl(data.url)
      }
    })
  }
  const addThisFont = (e)=>{
    FontJson.forEach((data) =>{
      if (data.tag == e.target.value) {
        setcurrentFont(data.name)
      }
    })
  }
  const addThisBadge1 = (e)=>{
    BadgesJson.forEach((data) =>{
      if (data.tag == e.target.value) {
        setBadge1Name(data.tag)
        setBadge1Url(data.url)
      }
    })
  }
  const addThisBadge2 = (e)=>{
    BadgesJson.forEach((data) =>{
      if (data.tag == e.target.value) {
        setBadge2Name(data.tag)
        setBadge2Url(data.url)
      }
    })
  }
  const addThisBadge3 = (e)=>{
    BadgesJson.forEach((data) =>{
      if (data.tag == e.target.value) {
        setBadge3Name(data.tag)
        setBadge3Url(data.url)
      }
    })
  }
  const addThisBadge4 = (e)=>{
    BadgesJson.forEach((data) =>{
      if (data.tag == e.target.value) {
        setBadge4Name(data.tag)
        setBadge4Url(data.url)
      }
    })
  }
  const addThisColor = (e)=>{
    setcolor(e.target.value)
  }
  const SubmitForm = (e) => {
    e.preventDefault();
    getPassport().then((data)=>{
    axios.post(`https://reo-fernandes.xyz/api/updateprofile/${data.user.id}`, {
      Description:Description,
      color:color,
      profile:currentprofilename,
      background:CurrentBackgroundName,
      birthday:birthdayDisplay,
      font:currentFont,
      badge1:Badge1Name,
      badge2:Badge2Name,
      badge3:Badge3Name,
      badge4:Badge4Name
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  })
  };
  return (
    <>
      <div>
        <div className="md:grid md:grid-cols-1 md:gap-6 font-poppins">
          <div className="mt-2 md:col-span-2 md:mt-0">
            <form onSubmit={SubmitForm} method="POST">
              <div className="md:col-span-1">
                <div className="px-4">
                  <h3 className="text-xl font-medium leading-6 text-gray-900 font-Bebas">
                    Profile
                  </h3>
                  <p className="mt-1 text-md text-gray-600">
                    This information will be displayed publicly so be careful
                    what you share.
                  </p>
                </div>
              </div>
              <div className=" sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-4 sm:p-6">
                  <div>
                    <label
                      htmlFor="about"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows={3}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-reored sm:text-sm"
                        placeholder="you@example.com"
                        name="description"
                        maxLength="150"
                        value={Description}
                        onChange={textAreaDetails}
                      />
                    </div>
                    <p className="mt-2 text-md text-gray-500">
                      Brief description for your profile
                    </p>
                  </div>
                  {/*<div>
                  <label className="block text-sm font-medium text-gray-700">Profile photo</label>
                  <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:bg-reored focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                    </div>
                  </div>
                    </div>*/}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="profile"
      
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Profile Image
                    </label>
                    <select
                    onChange={addThisProfile}
                      id="profile"
                      name="profile"
                      value={currentprofilename}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-reored focus:outline-none focus:ring-reored sm:text-sm"
                    >
                      {AllProfileAvailable.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="bg"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Background Image
                    </label>
                    <select
                      id="bg"
                      name="bg"
                      value={CurrentBackgroundName}
                      onChange={addThisBg}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-reored focus:outline-none focus:ring-reored sm:text-sm"
                    >
                              {AllBgAvailable.map((value) => (
                <option value={value}>{value}</option>
              ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="font"
                     
                      
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Choose your font
                    </label>
                    <select
                      id="font"
                      name="font"
                      onChange={addThisFont}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-reored focus:outline-none focus:ring-reored sm:text-sm"
                    >
                        {AllFontsAvailable.map((value) => (
                <option value={value}>{value}</option>
              ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="birthday"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Select Birthday
                    </label>
                    <input
                      type="date"
                      id="birthday"
                      name="birthday"
                      onChange={birthdayfun}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm sm:text-sm"
                    ></input>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="cor"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Select Color
                    </label>
                    <input
                      type="color"
                      id="cor"
                      name="cor"
                      value={color}
                      onChange={addThisColor}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white shadow-sm sm:text-sm"
                    ></input>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="b1"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Select Badge 1
                    </label>
                    <select
                      id="b1"
                      name="b1"
                      value={Badge1Name}
                      onChange={addThisBadge1}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-reored focus:outline-none focus:ring-reored sm:text-sm"
                    >
                      {AllBadgesAvailable.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="b2"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Select Badge 2
                    </label>
                    <select
                      id="b2"
                      name="b2"
                      value={Badge2Name}
                      onChange={addThisBadge2}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-reored focus:outline-none focus:ring-reored sm:text-sm"
                    >
                     {AllBadgesAvailable.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="b3"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Select Badge 3
                    </label>
                    <select
                      id="b3"
                      name="b3"
                      value={Badge3Name}
                      onChange={addThisBadge3}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-reored focus:outline-none focus:ring-reored sm:text-sm"
                    >
                      {AllBadgesAvailable.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="b4"
                      className="block text-md font-medium text-gray-700 font-Bebas"
                    >
                      Select Badge 4
                    </label>
                    <select
                      id="b4"
                      name="b4"
                      value={Badge4Name}
                      onChange={addThisBadge4}
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-reored focus:outline-none focus:ring-reored sm:text-sm"
                    >
                      {AllBadgesAvailable.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="px-4 py-3 text-right sm:px-6">
                  <button
                  type="submit"
     
                    className="inline-flex justify-center rounded-md border border-transparent bg-reored py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-reored focus:outline-none focus:ring-2 focus:bg-reored focus:ring-offset-2 font-Bebas cursor-pointer"
                  >
                    Apply Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
