import React from 'react'

const AllFont = () => {
    const obj = require('../../font.json')
  return (
    <>
    {obj.map((value) => (
      
            <div class="bg-gray-100 mt-2 md:ml-3 font-poppins">
            <div class="p-5">
              <h1 class="font-bebas text-black text-2xl">
                {value.name}
              </h1>
              <p class="font-poppins text-gray-500">
                {value.description}
              </p>
            </div>
            <div class="flex align-center justify-between p-5 items-center">
              <p class="font-poppins text-gray-800">Price : <b>{value.price}</b></p>
              <button class={ Number.isInteger(value.price) ? 'bg-reored p-2 rounded-md text-white' :  'hidden' }>
              { Number.isInteger(value.price) ? 'Buy' : 'Not For Sale' }
              </button>
            </div>
          </div>

    ))}
  </>
  )
}

export default AllFont