import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProfile, getTop, getUsernameAndUrl } from "../api";
import "../i.css";
const CustomizeRank = () => {
  const [rank, setrank] = useState({
    url: "",
    name: "",
    color: "",
    curLevel: "",
    curRank: "",
    profileimg: "",
  });
  const [percentage, setpercentage] = useState(0);
  const user = useParams().userid;
  useEffect(() => {
    getTop(user).then((data) => {
      data.find((element, index) => {
        if (element.userid == user) {
          const rankimage = require("../rankImage.json");
          getUsernameAndUrl(element.userid).then((details) => {
            setrank({
              url: details.displayAvatarURL,
              name: details.tag,
              color: element.rankcolor,
              curLevel: element.level,
              curRank: index + 1,
              profileimg: rankimage.find((e) => {
                if (e.tag == element.currankimg) {
                  return e;
                }
              }),
            });
          });
          let level = Math.floor(0.25 * Math.sqrt(element.points));
          let pointsNextLevel = Math.pow((level + 1) * 4, 2); //Required XP
          let perc = element.points / pointsNextLevel * 100
          var progress = Math.round(((element.points - (Math.pow((level) * 4, 2))) / (pointsNextLevel - (Math.pow((level) * 4, 2)))) * 100);
          console.log(`percentage is  ${perc}`) 
          setpercentage(progress);
        }
      });
    }); 
    
  }, []);
  const rankimg = {
    backgroundImage : `url(${rank.profileimg.url})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat" 
  }
  const usersbar = {
    background:rank.color,
    width: `${percentage}%`,
  };
  console.log(rank)
  return (
    <div className="rankcard"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        width: "1000px",
        letterSpacing: "1px",
      }}
    >
      <div
        className="UserDetails"
        style={{
          backgroundColor: "black",
          height: "310px",
          color: "white",
          textAlign: "center",
        }}
      >
        <div
          className="levelAndPos"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            padding: "10px",
          }}
        >
          <h1 style={{ textAlign: "left", paddingLeft: "5px" }}>
            #{rank.curRank}
          </h1>
          <h1 style={{ textAlign: "end", paddingRight: "5px" }}>
            Level : {rank.curLevel}
          </h1>
        </div>
        <div style={{ padding: "10px" }}>
          <img
            src={rank.url}
            alt="rankimg"
            style={{
              height: "100px",
              width: "100px",
              borderRadius: "50px",
              display: "inline"
            }}
          />
          <h3>{rank.name}</h3>
          <div class="meter">
            <span style={usersbar}></span>
          </div>
        </div>
      </div>
      <div
        className="TheirImage"
        style={rankimg}
      ></div>
    </div>
  );
};

export default CustomizeRank;
