import React from 'react'
const Error = () => {
  return (

  <div class="flex items-center justify-between">
  <div class='p-8 mx-8'>
      <p class="uppercase tracking-wide text-2xl text-rose-500 font-semibold ">404</p>
      <p class="text-4xl text-slate-800 font-bold p-1">Page Not Found</p>
      <p class="mt-2 text-slate-500">Couldnt find the page you were looking for</p>
      <button class='my-2 hover px-4 py-2 text-sm rounded-lg transition-all text-rose-500 font-semibold border border-rose-200 hover:text-white hover:bg-rose-600 hover:border-transparent hover:rounded-xl focus:outline-none focus:ring-2 focus:ring-rose-600 focus:ring-offset-2'>Go Home</button>
    </div>
    <div>
      <img class="hidden md:h-screen md:block md:object-cover" src="https://media.discordapp.net/attachments/816034553322799154/1090614149710622740/dream_TradingCard_4.jpg" alt="Train"/>
    </div>
  </div>
  )
}

export default Error