import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const ManageServers =() => {
  return (
    <>
    <Navbar></Navbar>
    <div>
        
    </div>
    <Footer></Footer>
    </>
  )
}

export default ManageServers