import React, { useEffect, useState } from "react";
import { getTop, getUsernameAndUrl } from "../api";
import "../index.css";
const Top = () => {
  const info = {
    display: "flex",
    margin: "8px",
  };
  const [u1, setu1] = useState({
    url: "",
    username: "reee",
  });
  const [u2, setu2] = useState({
    url: "",
    username: "reee",
  });
  const [u3, setu3] = useState({
    url: "",
    username: "reee",
  });
  const [u4, setu4] = useState({
    url: "",
    username: "reee",
  });
  const [u5, setu5] = useState({
    url: "",
    username: "reee",
  });
  useEffect(() => {
    getTop().then((data) => {
      console.log(data);
      getUsernameAndUrl(data[0].userid).then((data) => {
        setu1({
          url: data.displayAvatarURL,
          username: data.tag,
        });
      });

      getUsernameAndUrl(data[1].userid).then((data) => {
        setu2({
          url: data.displayAvatarURL,
          username: data.tag,
        });
      });

      getUsernameAndUrl(data[2].userid).then((data) => {
        setu3({
          url: data.displayAvatarURL,
          username: data.tag,
        });
      });

      getUsernameAndUrl(data[3].userid).then((data) => {
        setu4({
          url: data.displayAvatarURL,
          username: data.tag,
        });
      });

      getUsernameAndUrl(data[4].userid).then((data) => {
        setu5({
          url: data.displayAvatarURL,
          username: data.tag,
        });
      });
    });
  }, []);

  return (
    <div
      class="bgimg"
      style={{
        backgroundPosition: "center",
        color: "rgba(35, 31, 32, 1)",
        height: "1000px",
        width: "600px",
        marginLeft: "0px",
        marginTop: "0px",
        backgroundImage:
          "url(https://www.riotgames.com/darkroom/1440/d0807e131a84f2e42c7a303bda672789:3d02afa7e0bfb75f645d97467765b24c/valorant-offwhitelaunch-keyart.jpg)",
      }}
    >
      <div class="card" style={{ height: "1000px" }}>
        <div class="images">
          <img
            src="https://media.tenor.com/h-UqWpHxyvAAAAAC/my-hero-academia-worlds-hero-mission.gif"
            height="300"
            width="600"
            alt="season"
          />
        </div>
        <div class="info" style={info}>
          <h2
            style={{
              fontFamily: "bebas neue",
              fontSize: "35px",
              marginTop: "20px",
            }}
          >
            #1
          </h2>
          <img
            src={u1.url}
            height="80px"
            width="80px"
            alt="top1"
            style={{ borderRadius: "50px", marginLeft: "15px" }}
          />
          <h1
            style={{
              fontFamily: "bebas neue",
              fontSize: "25px",
              marginTop: "20px",
              paddingLeft: "10px"
            }}
          >
            {u1.username}
          </h1>
        </div>
        <div class="info" style={info}>
          <h2
            style={{
              fontFamily: "bebas neue",
              fontSize: "35px",
              marginTop: "20px",
            }}
          >
            #2
          </h2>
          <img
            src={u2.url}
            height="80px"
            width="80px"
            alt="top2"
            style={{ borderRadius: "50px", marginLeft: "15px" }}
          />
          <h1
            style={{
              fontFamily: "bebas neue",
              fontSize: "25px",
              marginTop: "20px",
              paddingLeft: "10px"
            }}
          >
           {u2.username}
          </h1>
        </div>
        <div class="info" style={info}>
          <h2
            style={{
              fontFamily: "bebas neue",
              fontSize: "35px",
              marginTop: "20px",
            }}
          >
            #3
          </h2>
          <img
            src={u3.url}
            height="80px"
            width="80px"
            alt="top3"
            style={{ borderRadius: "50px", marginLeft: "15px" }}
          />
          <h1
            style={{
              fontFamily: "bebas neue",
              fontSize: "25px",
              marginTop: "20px",
              paddingLeft: "10px"
            }}
          >
        {u3.username}
          </h1>
        </div>
        <div class="info" style={info}>
          <h2
            style={{
              fontFamily: "bebas neue",
              fontSize: "35px",
              marginTop: "20px",
            }}
          >
            #4
          </h2>
          <img
            src={u4.url}
            height="80px"
            width="80px"
            alt="top4"
            style={{ borderRadius: "50px", marginLeft: "15px" }}
          />
          <h1
            style={{
              fontFamily: "bebas neue",
              fontSize: "25px",
              marginTop: "20px",
              paddingLeft: "10px"
            }}
          >
           {u4.username}
          </h1>
        </div>
        <div class="info" style={info}>
          <h2
            style={{
              fontFamily: "bebas neue",
              fontSize: "35px",
              marginTop: "20px",
            }}
          >
            #5
          </h2>
          <img
            src={u5.url}
            height="80px"
            width="80px"
            alt="top5"
            style={{ borderRadius: "50px", marginLeft: "15px" }}
          />
          <h1
            style={{
              fontFamily: "bebas neue",
              fontSize: "25px",
              marginTop: "20px",
              paddingLeft: "10px"
            }}
          >
           {u5.username}
          </h1>
        </div>
        <div
          style={{
            textAlign: "right",
            margin: "30px 20px",
          }}
        >
          <h1
            style={{
              fontFamily: "Bebas Neue",
              fontSize: "30px",
            }}
          >
            Season 1 Ends On Jan 23 2023
          </h1>
          <p
            style={{
              fontFamily: "Bebas Neue",
            }}
          >
            Theme : One Punch Man
          </p>
        </div>
      </div>
    </div>
  );
};

export default Top;
