import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { AtomCurrentFontName } from '../atom/AtomCurrentFontName'
import { AtomDescription } from '../atom/AtomDescription'
import './font.css'
const Description = () => {
  const curfont = useRecoilValue(AtomCurrentFontName)
  const ThisDescription = useRecoilValue(AtomDescription)
  const fontfordesc= { 
    fontFamily:curfont
  }
  console.log(fontfordesc)
  return (
    <div class='break-words p-2'>
        <h1>Description</h1>
        <p style={fontfordesc}>{ThisDescription}</p>
    </div>
  )
}

export default Description