import React, { useEffect, useState } from "react";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Outlet, Link } from "react-router-dom";
import { getPassport, getUsernameAndUrl } from "../api";
import { AtomUsersUrl } from "./atom/AtomUsersUrl";
import { AtomLog } from "./atom/Atomlog";
import { useRecoilState } from "recoil";
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const YouAreIn = ({images, usersname}) =>{
  return (
    <>
    
        <Link to={`/profile`} class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Customize Profile</Link>


        <Link to={`/Addserver`} class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Add Your Server</Link>

        <Link to={`/Marketplace`} class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Marketplace</Link>
    </>
  );
}
const NotLoggedIn = () =>{
  return (
    <>
        <a href={`/auth/discord`} class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">login</a>
    </>
  );
}
const ProfileData = ()=>{
  const [log, setlog] = useRecoilState(AtomLog)
  const [image, setimage] = useRecoilState(AtomUsersUrl)
  useEffect(() => {
      getPassport().then((data) => {
          console.log(data);
          if(data.user.id == 'NOPASS')
          setlog(false)
          else {
          setlog(true)
          getUsernameAndUrl(data.user.id).then(u=>{
            console.log(u) 
            setimage(u.displayAvatarURL)
          })
          }
        });  
  }, [])
  if(log) { 
  return(
  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
  {/* Profile dropdown */}
  <Menu as="div" className="relative ml-3">
    <div>
      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
        <span className="sr-only">Open user menu</span>
        <img
          className="h-8 w-8 rounded-full"
          src={image}
          alt=""
        />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <Link
              href="#"
              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
            >
              Your Profile
            </Link>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <Link
              href="/api/logout"
              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
            >
              Sign out
            </Link>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>
</div>)
  }
}
const Data = () => {
  const [log, setlog] = useState(false)
  useEffect(() => {
      getPassport().then((data) => {
          console.log(data);
          if(data.user.id == 'NOPASS')
          setlog(false)
          else {
          setlog(true)
          }
        });  
  }, [])
  if(log)
  {
    return <YouAreIn/>
  } else {
    return <NotLoggedIn></NotLoggedIn>
  }
}
const Navbar = () => {
  return (
    <Disclosure as="nav" className="bg-gray-800">
    {({ open }) => (
      <>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button*/}
              <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="block h-8 w-auto lg:hidden"
                  src="https://cdn.glitch.global/ad440fa6-74ef-412e-a436-c369122705f0/Ellipse%204.png?v=1670917774661"
                  alt="Your Company"
                />
                <img
                  className="hidden h-8 w-auto lg:block"
                  src="https://cdn.glitch.global/ad440fa6-74ef-412e-a436-c369122705f0/Ellipse%204.png?v=1670917774661"
                  alt="Your Company"
                />
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  <Data/>
                </div>
              </div>
            </div>
            <ProfileData/>
          </div>
        </div>

        <Disclosure.Panel className="sm:hidden">
          <div className="space-y-1 px-2 pt-2 pb-3">
          <Data/>
          </div>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
  
  
  )
}

export default Navbar