import React, { useEffect, useState } from "react";
import { getPassport, getProfile, getUsernameAndUrl } from "../api";
import Profile from "../components/Profile";

import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { atom, RecoilRoot, useRecoilState, selector } from "recoil";
import { AtomCurrentProfileName } from "../components/atom/AtomCurrentProfileName";
import { AtomUsersUrl } from "../components/atom/AtomUsersUrl";
import { AtomUsername } from "../components/atom/AtomUsername";
import { AtomWork } from "../components/atom/AtomWork";
import { AtomDescription } from "../components/atom/AtomDescription";
import { AtomUserid } from "../components/atom/AtomUserid";
import { AtomLevel } from "../components/atom/AtomLevel";
import { AtomBirthdayDisplay } from "../components/atom/AtomBirthdayDisplay";
import { AtomCurrentFontName } from "../components/atom/AtomCurrentFontName";
import { AtomColor } from "../components/atom/AtomColor";
import { AtomBalanceRP } from "../components/atom/AtomBalanceRP";
import { AtomCurrentProfileUrl } from "../components/atom/AtomCurrentProfileUrl";
import { AtomCurrentProfileAvailable } from "../components/atom/AtomCurrentProfileAvailable";
import { AtomCurrentBadgeAvailable } from "../components/atom/AtomCurrentBadgeAvailable";
import { AtomCurrentBgName } from "../components/atom/AtomCurrentBgName";
import { AtomCurrentBgAvailable } from "../components/atom/AtomCurrentBgAvailable";
import { AtomCurrentBadge1Name } from "../components/atom/AtomCurrentBadge1Name";
import { AtomCurrentBadge1Url } from "../components/atom/AtomCurrentBadge1Url";
import { AtomCurrentBadge2Name } from "../components/atom/AtomCurrentBadge2Name";
import { AtomCurrentBadge2Url } from "../components/atom/AtomCurrentBadge2Url";
import { AtomCurrentBadge3Name } from "../components/atom/AtomCurrentBadge3Name";
import { AtomCurrentBadge3Url } from "../components/atom/AtomCurrentBadge3Url";
import { AtomCurrentBadge4Name } from "../components/atom/AtomCurrentBadgeName";
import { AtomCurrentBadge4Url } from "../components/atom/AtomCurrentBadge4Url";
import { AtomCurrentBgUrl } from "../components/atom/AtomCurrentBgUrl";
import Form from "../components/Form";
import { AtomCurrentFontAvailable } from "../components/atom/AtomCurrentFontAvailable";
const Getprofile = () => {
  const [currentprofilename, setcurrentprofilename] = useRecoilState(AtomCurrentProfileName);
  const [usersprofile, setusersprofile] = useRecoilState(AtomUsersUrl);
  const [usersname, setusersname] = useRecoilState(AtomUsername);
  const [work, setwork] = useRecoilState(AtomWork);
  const [Description, setDescription] = useRecoilState(AtomDescription);
  const [userid, setuserid] = useRecoilState(AtomUserid);
  const [level, setlevel] = useRecoilState(AtomLevel);
  const [birthdayDisplay, setbirthdayDisplay] =useRecoilState(AtomBirthdayDisplay);
  const [font, setfont] = useRecoilState(AtomCurrentFontName);
  const [color, setcolor] = useRecoilState(AtomColor);
  const [currentFont, setcurrentFont] = useRecoilState(AtomCurrentFontName);
  const [AllFontsAvailable, setAllFontsAvailable] = useRecoilState(AtomCurrentFontAvailable)
  const [BalanceRp, setBalanceRp] = useRecoilState(AtomBalanceRP);
  const [ProfileimageUrl, setProfileimageurl] = useRecoilState(AtomCurrentProfileUrl);
  const [AllProfileAvailable, setAllProfileAvailable] = useRecoilState(AtomCurrentProfileAvailable );
  const [AllBadgesAvailable, setAllBadgesAvailable] = useRecoilState(AtomCurrentBadgeAvailable);
  const [CurrentBackgroundName, setCurrentBackgroundName] =useRecoilState(AtomCurrentBgName);
  const [CurrentBackgroundUrl, setCurrentBackgroundUrl] =useRecoilState(AtomCurrentBgUrl);
  const [AllBgAvailable, setAllBgAvailable] = useRecoilState(AtomCurrentBgAvailable);
  const [Badge1Name, setBadge1Name] = useRecoilState(AtomCurrentBadge1Name);
  const [Badge1Url, setBadge1Url] = useRecoilState(AtomCurrentBadge1Url);
  const [Badge2Name, setBadge2Name] = useRecoilState(AtomCurrentBadge2Name);
  const [Badge2Url, setBadge2Url] = useRecoilState(AtomCurrentBadge2Url);
  const [Badge3Name, setBadge3Name] = useRecoilState(AtomCurrentBadge3Name);
  const [Badge3Url, setBadge3Url] = useRecoilState(AtomCurrentBadge3Url);
  const [Badge4Name, setBadge4Name] = useRecoilState(AtomCurrentBadge4Name);
  const [Badge4Url, setBadge4Url] = useRecoilState(AtomCurrentBadge4Url);

  useEffect(() => {
    (async () => {
      try {
        getPassport().then((pass) =>{
          getUsernameAndUrl(pass.user.id).then((data) => {
            setusersprofile(data.displayAvatarURL);
            setusersname(data.tag);
          });
          getProfile(pass.user.id).then((data) => {
            setcurrentprofilename(data.curprofile);
            setwork(data.work);
            setDescription(data.description);
            setuserid(data.userid);
            setlevel(data.level);
            setbirthdayDisplay(data.birthday);
            setAllFontsAvailable(data.font);
            setcolor(data.color);
            setfont(data.curFont);
            setBalanceRp(data.balancerp);
            setAllProfileAvailable(data.profile);
            setAllBadgesAvailable(data.badges);
            setCurrentBackgroundName(data.curprofilebg);
            setAllBgAvailable(data.banner);
            const badgesObj = require("../badges.json");
            badgesObj.forEach((d) => {
              
              if (d.tag == data.curbadges[0]) {
                console.log(d)
                setBadge1Name(d.tag);
                setBadge1Url(d.url);
              }
            });
            badgesObj.forEach((d) => {
              if (d.tag == data.curbadges[1]) {
                setBadge2Name(d.tag);
                setBadge2Url(d.url);
              }
            });
            badgesObj.forEach((d) => {
              if (d.tag == data.curbadges[2]) {
                setBadge3Name(d.tag);
                setBadge3Url(d.url);
              }
            });
            badgesObj.forEach((d) => {
              if (d.tag == data.curbadges[3]) {
                setBadge4Name(d.tag);
                setBadge4Url(d.url);
              }
            });
            const obj = require("../profileimg.json");
            obj.forEach((d) => {
              if (d.tag == data.curprofile) {
                setProfileimageurl(d.url);
                setcurrentprofilename(d.tag);
              }
            });

            const bgobj = require("../BackgroundImage.json");
            bgobj.forEach((d) => {
              console.log(d)
              if (d.tag == data.curprofilebg) {
                setCurrentBackgroundName(d.tag);
                setCurrentBackgroundUrl(d.url);
              }
            });
          });
        })

      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      <Navbar />
      <div class="block mt-5">
        <Profile />
        <Form class="block lg:flex" />
      </div>
      <Footer></Footer>
    </>
  );
};

export default Getprofile;
