import React, {useState} from 'react'
import { useRecoilValue } from 'recoil'
import { AtomWork } from '../atom/AtomWork'


const Workinfo = () => {
  const userswork = useRecoilValue(AtomWork)
  return (<h1 class='p-2'>{userswork}</h1>)
}

export default Workinfo