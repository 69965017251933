import React, { useState } from 'react'
import '../index.css'
const Responsive = () => {
    const [click, setclick] = useState(false)
  return (
    <nav className="navbar">
        <a href='google.com'>
        <img
        src="https://cdn.glitch.global/ad440fa6-74ef-412e-a436-c369122705f0/companylogo.png?v=1670499529666"
        alt="logo"
      />
        </a>
      
    <div>
    <ul id="navbar" className={click ? '#navbar active':'#navbar'}>
      <li>
        Home
      </li>
      <li>
        Servers        
      </li>
      <li>
        about
      </li>
    </ul>
    </div>
    <div id='mobile' className={click ? "fa fa-times-circle" : "fa-solid fa-ellipsis"} onClick={
  () => setclick(!click )
    }>
    </div>
  </nav>
  )
}

export default Responsive