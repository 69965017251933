import React, { useEffect, useState } from 'react'
import Workinfo from './profilecard/Workinfo'
import '../index.css'
import MainImage from './profilecard/MainImage'
import UserDetails from './profilecard/UserDetails'
import UserLevelsInfo from './profilecard/UserLevelsInfo'
import Description from './profilecard/Description'
import { useRecoilValue } from 'recoil'
import { getPassport } from '../api'
import { Navigate } from 'react-router-dom';
import { AtomColor } from './atom/AtomColor'
import { AtomCurrentProfileUrl } from './atom/AtomCurrentProfileUrl'
import { AtomCurrentBgUrl } from './atom/AtomCurrentBgUrl'

/*const YouAreIn = ({images, usersname}) =>{

}
const Data = () => {
  const [log, setlog] = useState(false)
  useEffect(() => {
      getPassport().then((data) => {
          console.log(data);
          if(data.user.id == 'NOPASS')
          {
            setlog(false)
          } 
          else {
          setlog(true)
          }
          
        });  
  }, [])
  if(log == true)
  return <YouAreIn/>
  else
  return <Navigate to="/auth/discord"/>
}*/

const Profile = () => {
  const mycolor = useRecoilValue(AtomColor)
  const currentBgUrl = useRecoilValue(AtomCurrentBgUrl)
const image = {
  backgroundImage: `url('${currentBgUrl}')`,
}
const stylec = {
color:mycolor,
backgroundColor: 'rgba(82,84,85,.25)'
}
  return (
    <>
    <div className="bgimg" style={image} class='mx-2  bg-center'>
          <div className="card" style={stylec} class='backdrop-blur-xl'>
            <Workinfo></Workinfo>
            <MainImage></MainImage>
            <UserDetails></UserDetails>
            <UserLevelsInfo ></UserLevelsInfo>
            <Description></Description>
          </div>
      </div>
      </>
  );
}

export default Profile

