import React from "react";
import "../index.css";
import { FaDiscord, FaSubscript } from "react-icons/fa";
import SelectLanguage from "./SelectLanguage";
const Footer = () => {
  return (
    <div class='bg-black text-white p-2 font-poppins'>
    <div className="footer" class="block md:flex  justify-around items-center m-2">

        <div>
          <h1 class=' text-xl sm:text-3xl font-bebas'>Select Language</h1>
          <SelectLanguage/>
        </div>
        <div>
        <form className="mt-8 space-y-3 mb-2" action="#" method="POST">
          <h1 class=' text-xl sm:text-3xl font-bebas'>Subscribe to our Newsletter</h1>
          <p>Get Information About our projects through these Newsletter</p>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            class="block w-full rounded-sm rounded-t-md  px-3 py-2 text-white placeholder-gray-500 sm:text-sm bg-transparentwhite appearance-none"
            placeholder="Email address"
          />
          <button type='submit' value='submit' class='bg-reored p-2 rounded-lg'>Submit</button>
            </form>

        </div>
    </div>
    </div>
  );
};

export default Footer;
