import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../i.css";
const Apex = () => {
  const [details, setdetails] = useState({
    name:'',
    level:'',
    pass:'',
    legend:'',
    status:'',
    arenas:'',
    battleroyale:'',
    id: ''
  })
  const username = useParams().name;
  const plat =useParams().platform
  useEffect(() => {
 
    fetch(`https://api.mozambiquehe.re/bridge?version=5&platform=${plat}&player=${username}&auth=w796XVmBr3FSWfs1uXFP`)
    .then(res => res.json()).then(json => {
      console.log(json)
      setdetails({
        name:json.global.name,
        level:json.global.level,
        pass:json.global.battlepass.level,
        legend:json.realtime.selectedLegend,
        status:json.realtime.currentState,
        arenas : json.global.arena.rankImg,
        battleroyale : json.global.rank.rankImg,
        id:json.global.uid
      })
    
    })
  
    
  }, [])
  
  return (
    <div>
      <div class="gameimg">
        <div class="gamecard">
          <div class="gameimage">
            <img
              src="https://media1.giphy.com/media/YNzNIHCYPNHecpHxTx/giphy.gif?cid=ecf05e47nht4vd231o3a5gcr14n0z61spbkop2o8nbq86cm0&rid=giphy.gif&ct=g"
              height="350"
              width="734"
            alt="titleimg"/>
          </div>
          <div className="userdetails" style={{padding:'16px'}}>
            <h1>{details.name}</h1>
            <p>{details.id}</p>
          </div>
          <div style={{ display: "grid",gridTemplateColumns: "10% 20% 30% 30% 30%",padding: "20px" }}>
            <div>
              <h1>Level</h1>
              <p>{details.level}</p>
            </div>
            <div>
              <h1>Battle Pass</h1>
              <p>{details.pass}</p>
                </div>
                <div>
            <h1>Selected Legend</h1>
            <p>{details.legend}</p>
            </div>
            <div>
            <h1>Status</h1>
            <p>{details.status}</p>
            </div>
                </div>
          <div className="badges" style={{textAlign:'center', display:'flex', marginTop:'10px'}}>
            <div>
              <h1>Arenas</h1>
              <img src={details.arenas} alt="arenas" height='150px' width='150px'/>
                </div>
                <div>
              <h1>Battle Royale</h1>
              <img src={details.battleroyale} alt="br" height='150px' width='150px'/>
            </div>
      
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apex;
