import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import React, { useState, useEffect } from "react";
import { getServers } from "../api";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Server = () => {
  const [serverlist, setserverlist] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        getServers().then((data) => {
          setserverlist(data);
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);
  const findthis = (e) => {
    if (e.target.value.length > 0) {
      console.log(e.target.value);
      //const data =  serverlist.filter(server => )
    }
  };
  return (
    <>
      <Navbar></Navbar>
      <div class="text-center mt-3 mx-10 lg:mx-80">
        <h1 class="font-Bebas text-2xl">List of Servers</h1>
        <form>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            class="block w-full font-poppins px-3 py-2  placeholder-gray-500 sm:text-sm bg-transparentwhite appearance-none text-gray-700 border border-gray-300"
            placeholder="Enter Server Name"
          />
        </form>
      </div>

      <div class="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 m-3">
        <div class="bg-gray-100 mt-2 md:ml-3">
          <div class='aspect-w-1 aspect-h-1 w-full  roundedt-lg bg-gray-200 xl:aspect-w-5 xl:aspect-h-5'>
            <img src="https://images.pexels.com/photos/11207633/pexels-photo-11207633.jpeg" alt="serverimage" class='h-full w-full object-cover object-center' />
          </div>
          <div class='p-3'>
            <h1 class="font-Bebas text-black text-2xl">Abstract World</h1>
            <p class="font-poppins text-gray-500">
              Explore our Abstract world blah blah
            </p>
          </div>
          <div class="flex align-center justify-between p-3">
            <p class="font-poppins text-gray-800">124 Members</p>
            <button class="bg-reored p-2 rounded-md text-white">JOIN</button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Server;
